import { axios } from '@/plugins/axios'
export function getOpenId(parameter) {
  return axios.request({
    method: 'post',
    params: {
      module: 'app',
      action: 'wechat',
      app: 'wechatOpenLogin',
      ...parameter,
    },
  })
}
