<template>
  <div class="box">
    <div class="download">
      <img src="@/assets/img/component/logo-icon@2x.png" alt="" />
      <div class="center">
        <div class="title">1号奢仓</div>
        <div class="sub">专业奢侈品、豪车共享租用平台</div>
      </div>
      <div class="btn" @click.stop="downloadApp">立即下载</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    downloadApp() {
      window.location.href =
        'https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc'
    },
  },
}
</script>

<style lang="scss" scoped>
.box {
  height: 108px;
}
.download {
  padding: 0 20px;
  height: 108px;
  background: #ffffff;
  display: flex;
  align-items: center;

  position: fixed;
  width: 100%;
  z-index: 200;
  img {
    width: 76px;
    height: 76px;
  }
  .center {
    display: flex;
    // align-items: center;
    height: 76px;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    .title {
      font-size: 31px;
      font-weight: 600;
      color: #333333;
    }
    .sub {
      font-size: 25px;
      font-weight: 300;
      color: #999999;
    }
  }
  .btn {
    width: 168px;
    height: 56px;
    background: linear-gradient(0deg, #323234, #606061);
    border-radius: 28px;
    font-size: 25px;
    font-weight: 600;
    color: #ffffff;
    line-height: 56px;
    text-align: center;
    margin-left: 92px;
  }
}
</style>
