<template>
  <div class="home">
    <div class="station">
      <!--  <van-nav-bar title="奢仓福利" left-arrow /> -->
      <download />
      <div class="station-box">
        <div class="booth"></div>
        <div class="congratulations">
          <div class="txt">恭喜您，获得7天黑卡VIP体验券</div>
          <div class="flex">
            <input class="ipt" v-model="iptval" type="text" />
            <div
              class="exchange-btn"
              v-clipboard:copy="iptval"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
            >
              复制兑换码
            </div>
          </div>
        </div>
        <div class="tips">
          <div class="first">
            <van-icon
              size="35"
              name="https://t.yhaocang.com/upload/wechat_h5/wechatImg/warn@2x.png"
            /><span>温馨提示</span>
          </div>
          <div class="second">
            请在<span>有效期24小时内</span>兑换，避免过期无法兑换
          </div>
          <div class="second">每个微信号仅可得到一个兑换码</div>
        </div>
        <div class="process">
          <div class="first">
            <van-icon
              size="35"
              name="https://t.yhaocang.com/upload/wechat_h5/wechatImg/succ@2x.png"
            /><span>兑换使用流程</span>
          </div>
          <div class="second">
            <img
              src="https://t.yhaocang.com/upload/wechat_h5/wechatImg/bottombg@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="zhanwei"></div>
  </div>
</template>

<script>
import { getOpenId } from '@/api/index'
// @ is an alias to /src
import download from '../components/download'
export default {
  name: 'Home',
  components: { download },
  data() {
    return {
      iptval: '',
      url: '',
    }
  },
  created() {
    this.getcode()
  },
  methods: {
    onCopySuccess() {
      this.$toast('复制成功')
    },
    onCopyError() {
      this.$toast('复制失败')
    },
    async getcode() {
      const res = await getOpenId({
        returnUrl: window.location.href,
        code: this.$route.query.code || '',
      })
      if (res.data.code == 302) {
        console.log(res.data.data, '微信回调')
        window.location.href = res.data.data.url
      } else if (res.data.code == 200) {
        this.iptval = res.data.data.code
      } else {
        this.$toast(res.data.message)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.station {
  // box-sizing: border-box;
  .station-box {
    background: url(https://t.yhaocang.com/upload/wechat_h5/wechatImg/fulibg@2x.png)
      0 0 no-repeat;
    background-size: 100%;
  }
  .booth {
    height: 118px;
  }
  .congratulations {
    width: 669px;
    height: 305px;
    background: url(https://t.yhaocang.com/upload/wechat_h5/wechatImg/ipt@2x.png)
      0 0 no-repeat;
    background-size: 100%;
    margin: 0 auto 81px;
    padding: 57px 31px;
    box-sizing: border-box;
    .txt {
      font-size: 31px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 60px;
    }
    .flex {
      display: flex;
      align-items: center;
      .ipt {
        width: 386px;
        height: 76px;
        border: 1px solid #eeeeee;
        border-radius: 7px;
        margin-right: 16px;
      }
      .exchange-btn {
        width: 205px;
        height: 76px;
        background: #333333;
        border-radius: 7px;
        font-size: 28px;
        font-weight: 600;
        color: #ffffff;
        line-height: 76px;
        text-align: center;
        letter-spacing: 3px;
      }
    }
  }
  .tips {
    width: 669px;
    margin: 0 auto 0;
    line-height: 42px;
    font-size: 27px;
    .first {
      display: flex;
      align-items: center;
      margin-bottom: 47px;
      span {
        font-size: 35px;
        font-weight: 600;
        color: #333333;
        margin-left: 22px;
      }
    }
    .second {
      font-size: 25px;
      font-weight: 400;
      color: #9d8c63;
      span {
        color: #ff0000;
        font-weight: 600;
      }
    }
  }
  .process {
    width: 669px;
    margin: 113px auto 0;
    .first {
      display: flex;
      align-items: center;
      margin-bottom: 87px;
      span {
        font-size: 35px;
        font-weight: 600;
        color: #333333;
        margin-left: 22px;
      }
    }
    .second {
      width: 669px;
      margin: 0 auto;
      img {
        width: 669px;
      }
    }
  }
}
.zhanwei {
  width: 100%;
  height: 100px;
}
</style>
